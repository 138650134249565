import React from "react";

const sizeClasses = {
  txtInterMedium14Yellow800: "font-inter font-medium",
  txtMontserratMedium20: "font-medium font-montserrat",
  txtInterRegular20: "font-inter font-normal",
  txtInterRegular20Gray90001: "font-inter font-normal",
  txtInterMedium24: "font-inter font-medium",
  txtMontserratRegular20: "font-montserrat font-normal",
  txtInterSemiBold24: "font-inter font-semibold",
  txtInterMedium28: "font-inter font-medium",
  txtMontserratSemiBold56: "font-montserrat font-semibold",
  txtGeneralSansRegular18: "font-generalsans font-normal",
  txtInterMedium20Green800: "font-inter font-medium",
  txtMontserratBold4395: "font-bold font-montserrat",
  txtInterRegular16Green800: "font-inter font-normal",
  txtMontserratMedium20Green500: "font-medium font-montserrat",
  txtInterRegular20Green800: "font-inter font-normal",
  txtInterRegular20Bluegray90001: "font-inter font-normal",
  txtMontserratMedium11: "font-medium font-montserrat",
  txtInterMedium20Bluegray90001: "font-inter font-medium",
  txtInterRegular16Bluegray500: "font-inter font-normal",
  txtInterMedium20WhiteA700: "font-inter font-medium",
  txtInterSemiBold36: "font-inter font-semibold",
  txtInterSemiBold16: "font-inter font-semibold",
  txtInterMedium14: "font-inter font-medium",
  txtMontserratRegular20WhiteA70099: "font-montserrat font-normal",
  txtMontserratRegular20Gray90099: "font-montserrat font-normal",
  txtInterSemiBold40: "font-inter font-semibold",
  txtMontserratRomanSemiBold24: "font-montserrat font-semibold",
  txtInterMedium20: "font-inter font-medium",
  txtInterSemiBold20: "font-inter font-semibold",
  txtGeneralSansMedium18: "font-generalsans font-medium",
  txtInterRegular12: "font-inter font-normal",
  txtInterMedium20Yellow800: "font-inter font-medium",
  txtMontserratBold6343: "font-bold font-montserrat",
  txtMontserratSemiBold4395: "font-montserrat font-semibold",
  txtInterRegular16: "font-inter font-normal",
  txtInterSemiBold40Indigo800: "font-inter font-semibold",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
