import React from "react";
import { useNavigate } from "react-router-dom"

import { Button, Img, Line, Text } from "components";
import { merchants } from "./merchants";


const Home = () => {
  const navigate = useNavigate();
  const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>

      <div className="bg-white-A700 flex flex-col font-generalsans items-center justify-start mx-auto w-auto sm:w-full md:w-full">
        <header
          className="common-pointer bg-white-A700 flex flex-col items-center justify-center md:px-5 w-full"

        >
          <div className="bg-indigo-800 flex flex-1 flex-col items-center justify-start w-full">
            <div className="bg-indigo-800 flex sm:flex-col flex-row gap-2 sm:hidden items-center justify-end w-full">
              <Button
                className="font-medium leading-[normal] min-w-[252px] text-base text-center text-white-A700"
                shape="round"
                size="sm"
              >
                support@digistoreafrica.com
              </Button>
              <Button
                className="font-medium leading-[normal] min-w-[142px] text-base text-center text-white-A700"
                shape="round"
                size="sm"
              >
                +233303967121
              </Button>
              <Button
                className="font-medium leading-[normal] min-w-[151px] text-base text-center text-white-A700"
                shape="round"
                size="sm"
              >
                +233302735088
              </Button>
            </div>
          </div>

          <div className="bg-white-A700 flex md:flex-1 flex-row font-montserrat md:gap-10 items-center justify-between py-5 w-4/5 md:w-[100%]">
              <Img
                className="h-[63px] sm:h-[45px]"
                src="images/digi-mobile.png"
                alt="union"
              />
          </div>
        </header>
        <img
          className="object-cover w-full md:hidden max-h-[524px]" // Show on medium and larger screens
          src="images/img22.png"
          alt="rectangleFortyFourDesktop"
        />

        <img
          className="object-cover w-full hidden md:block" // Hide on medium and larger screens
          src="images/img22-mobile.png"
          alt="rectangleFortyFourMobile"
        />

        <div className="bg-white-A700 flex flex-col font-montserrat gap-[55px] items-center justify-start max-w-[1920px] pt-[120px] pb-[80px] md:py-[30px] sm:py-[30px] w-full" id="merchants">
          <div className="flex flex-col gap-9 items-center justify-start md:px-5 w-[616px] md:w-full">
            <Text
              className="leading-[116.00%] w-[700px] md:max-w-full sm:text-[20px] md:text-[39.95px] text-[43.95px] text-center text-indigo-800 tracking-[1.32px]"
              size="txtMontserratSemiBold4395"
            >
              Explore, Shop and Earn loyalty rewards with our partner merchants
            </Text>
            <Text
              className="leading-[150.00%] w-[1000px] md:max-w-full sm:text-[15px] text-center text-gray-900_99 text-xl tracking-[0.30px]"
              size="txtMontserratRegular20Gray90099"
            >
              Unlock amazing rewards from Airtime, Discount Vouchers, Event tickets and more, anytime you purchase online or cashless in shop with our Digistore merchants.
            </Text>
          </div>
          <div className="flex flex-col items-start justify-start max-w-[903px] md:px-5 w-full">
            <div className="flex flex-col gap-[18px] items-start justify-start w-full">
              <div className="md:gap-5 gap-[40px] grid md:grid-cols-2 grid-cols-3 justify-center min-h-[auto] w-full">
                { 
                  (merchants && merchants.length > 0) ?
                  merchants.map(function(merchant) 
                  { return (
                    <div key={merchant.title}>
                  <a href={(merchant.href2 && isAppleDevice) ? merchant.href2 : merchant.href} target="_blank" rel="noopener noreferrer">
                    <div className="h-[110px] relative w-full">
                      <Img
                        className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                        src={merchant.mainSrc}
                        alt={merchant.title}
                        title={merchant.title}
                      />
                      <Img
                        className="absolute h-16 justify-center inset-[0] m-auto object-contain w-16"
                        src={merchant.subSrc}
                        alt={merchant.alt}
                        title={merchant.title}
                      />
                    </div>
                    <Button
                      className="w-full cursor-pointer font-medium md:mt-0 my-2 text-center sm:text-sm text-xl tracking-[-0.40px] md:h-14 sm:h-10 sm:my-0 sm:mt-5 sm:w-full"
                      shape="round"
                      color="green_500_01"
                      size="lg"
                      variant="fill"
                    >
                      Buy Online
                    </Button>
                  </a>
                </div>
                  );
                })
                : <p>New merchants will be added soon...</p>
                }
              </div>

            </div>
          </div>
          <div className="block h-full sm:h-[200px]">  
          <div className="w-full h-[400px] relative font-medium md:mt-0 mt-5 text-center sm:text-sm text-xl tracking-[-0.40px] sm:h-[200px] sm:my-0 sm:mb-5 sm:w-full">      
           <img className="brightness-75 md:hidden sm:hidden sm:object-contain sm:w-full sm:h-[200px]" src="images/falalala-desktop.png" alt="Redeem rewards"/>
           <img className="hidden md:block sm:block brightness-75 sm:object-contain sm:w-full sm:h-[200px]" src="images/falalala-mobile.png" alt="Redeem rewards"/>
           <div className="absolute inset-[0] m-auto h-[300px] justify-center flex flex-col sm:mt-0 sm:h-[200px]">
            <p className="m-auto mb-0 text-white-A700 text-2xl sm:text-sm sm:mt-0 sm:m-0">Turn points into perks:<br/> Discounts, gifts, and more! Redeem now.</p>
            <a className="m-auto mt-10 w-full h-auto sm:mt-4 sm:mb-2" href="https://rewards.digistoreafrica.com" rel="noreferrer noopener">
              <Button
                className="w-1/6 h-auto m-auto mt-10 cursor-pointer font-medium text-center sm:text-sm text-xl tracking-[-0.40px] md:w-1/3 md:h-[60px] sm:h-10 sm:my-0 sm:mt-5 sm:w-1/2"
                shape="round"
                color="indigo_800"
                size="lg"
                variant="fill"
              >
                Redeem Rewards
              </Button>
            </a>
           </div>     
          </div>
          </div>
        </div>

        <div className="bg-gray-100 flex flex-col font-montserrat gap-[59px] h-[621px] md:h-auto items-center justify-start max-w-[1920px] py-[100px] w-full sm:mb-[-80px]">
          <div className="flex flex-col gap-[29px] items-center justify-start md:px-5 w-auto md:w-full">
            <Text
              className="leading-[116.00%] max-w-[764px] md:max-w-full md:text-5xl sm:text-[25px] text-[56px] text-center text-indigo-800"
              size="txtMontserratSemiBold56"
            >
              Join the Digipreneur community
            </Text>
            <Text
              className="leading-[150.00%] max-w-[669px] md:max-w-full sm:text-[15px] text-center text-gray-900_99 text-xl tracking-[0.30px]"
              size="txtMontserratRegular20Gray90099"
            >
              Connect with other entrepreneurs and like-minded business people in the Digipreneur community.
            </Text>
          </div>
          <div className="border border-green-500 border-solid flex flex-col font-inter items-center justify-center p-5 md:px-5 rounded-[34px] sm:w-[150px] w-[318px]">
            <a
              target="_blank" // Opens the link in a new tab/window
              rel="noopener noreferrer"
              href="https://forms.gle/TcEAqVyRLQ4uZVkn6"

            >
              <Text
                className="text-green-500 text-xl tracking-[0.50px] w-auto"
                size="txtInterMedium20"

              >
                Join Now
              </Text>
            </a>
          </div>
          <Img
            style={imageStyle}
            src="images/img_group44.svg"
            alt="groupFortyFour"
          />
        </div>
        <div className="bg-indigo-800 flex flex-col font-generalsans items-center justify-start py-[60px] w-full">
          <div className="flex flex-col gap-11 items-center justify-start max-w-[1335px] mx-auto md:px-5 w-4/5 md:w-full">
            <div className="flex flex-col gap-8 items-start justify-start w-full">
              <Line className="bg-white-A700_33 h-px w-full" />
              <div className="flex flex-row md:gap-10 items-center justify-between w-full">
                <div className="flex flex-row gap-2 items-center justify-start pl-1 pr-3 py-1 w-auto">
                  <Img
                    className="h-7 md:h-auto rounded-[50%] w-7"
                    src="images/img_ellipse1.png"
                    alt="ellipseOne"
                  />
                  <Text
                    className="text-lg text-white-A700_87 w-auto"
                    size="txtGeneralSansMedium18"
                  >
                    Ghana
                  </Text>
                </div>
                <div className="flex flex-row gap-3 items-start justify-start w-auto">
                  <Img
                    className="common-pointer h-5 w-5"
                    src="images/img_icons8twittercircledfilled.svg"
                    alt="icons8twitterci"
                    onClick={() => navigate("")}
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8facebook.svg"
                    alt="icons8facebook"
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8instagramnewfilled.svg"
                    alt="icons8instagram"
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8linkedin.svg"
                    alt="icons8linkedin"
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8youtubeplayfilled.svg"
                    alt="icons8youtubepl"
                  />

                </div>
              </div>
            </div>
            <div className="flex sm:flex-col flex-row md:gap-10 gap-[75px] items-start justify-start w-full">
              <Text
                className="max-w-[300px] md:max-w-full text-lg text-white-A700 tracking-[0.18px]"
                size="txtGeneralSansRegular18"
              >
                The Orangery, 98 La-Bawaleshi Rd, East Legon – Accra, Ghana
                Mon-Fri | 8:00am - 5:00pm
              </Text>
              <Text
                className="text-lg text-white-A700 md:w-full md:text-center tracking-[0.18px]"
                size="txtGeneralSansRegular18"
              >
                <>
                  (+233 302 735088) <br />
                  (+233 303 967121)
                </>
              </Text>
              <Text
                className="text-lg text-white-A700 tracking-[0.18px] w-auto"
                size="txtGeneralSansRegular18"
              >
                support@digistoreafrica.com
              </Text>
            </div>
            <div className="flex sm:flex-col flex-row md:gap-11 items-start justify-between w-full">
              <Text
                className="text-lg text-white-A700 tracking-[0.18px] w-auto"
                size="txtGeneralSansRegular18"
              >
                <a
                  href="javascript:"
                  className="text-green-500 font-generalsans text-left font-normal underline"
                >
                  iPay Solutions Limited
                </a>
                <span className="text-green-500 font-generalsans text-left font-normal">
                  {" "}
                </span>
                <span className="text-white-A700 font-generalsans text-left font-normal">
                  2023. All Rights Reserved.
                </span>
              </Text>
              <Text
                className="text-lg text-white-A700 tracking-[0.18px] w-auto"
                size="txtGeneralSansRegular18"
              >
                <span className="text-white-A700 font-generalsans text-left font-normal">
                  Designed by
                </span>
                <span className="text-green-500 font-generalsans text-left font-normal">
                  {" "}
                </span>
                <a
                  href="javascript:"
                  className="text-green-500 font-generalsans text-left font-normal underline"
                >
                  Simpliexpand
                </a>
              </Text>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;


const imageStyle = {
  display: 'block',
  width: '100%',
  height: 'auto',
  margin: '0 auto',
  padding: '0',
};