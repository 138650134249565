const merchants = [
    {
        "href": "https://dipnchipsgh.com/",
        "mainSrc": "images/img_rectangle102.png",
        "subSrc": "images/img_image15.png",
        "alt": "Dip and chips Ghana Logo",
        "title": "Dip and chips Ghana"
    },
    {
        "href": "http://orders.bukarestaurant.com",
        "mainSrc": "images/img_rectangle103.png",
        "subSrc": "images/img_maskgroup.png",
        "alt": "Buka Restaurant Logo",
        "title": "Buka Restaurant"
    },
    {
        "href": "https://orders.papaspizzagh.com",
        "mainSrc": "images/img_rectangle104.png",
        "subSrc": "images/img_logomain1.png",
        "alt": "Papa's Pizza Ghana Logo",
        "title": "Papa's Pizza Ghana"
    },
    {
        "href": "https://buy.digistoreafrica.com/waakyeguyguy/order",
        "mainSrc": "images/img_rectangle105.png",
        "subSrc": "images/img_logomer11155.png",
        "alt": "Waakye GuyGuy Logo",
        "title": "Waakye GuyGuy"
    },
    {
        "href": "https://play.google.com/store/apps/details?id=com.starbiteapp&hl=en&gl=US",
        "href2": "https://apps.apple.com/gh/app/starbites/id1573083411",
        "mainSrc": "images/img_rectangle106.png",
        "subSrc": "images/img_starbiteslogoplain.png",
        "alt": "Starbites Ghana logo",
        "title": "Starbites Ghana"
    },
    {
        "href": "http://www.popkulture22.com",
        "mainSrc": "images/img_rectangle107.png",
        "subSrc": "images/img_logomer06016_48x48.png",
        "alt": "Pop Kulture 22 Logo",
        "title": "Pop Kulture 22"
    },
    {
        "href": "https://buy.digistoreafrica.com/secondpartnerfoods",
        "mainSrc": "images/img_rectangle107_1.png",
        "subSrc": "images/img_logomer06016_2.png",
        "alt": "Second Partner Foods Logo",
        "title": "Second Partner Foods"
    },
    {
        "href": "https://micarshgrills.com/micarshgrills/order",
        "mainSrc": "images/img_rectangle107_2.png",
        "subSrc": "images/img_logomer06016_3.png",
        "alt": "Micarsh Grills Logo",
        "title": "Micarsh Grills"
    },
    {
        "href": "https://buy.digistoreafrica.com/bluecheese",
        "mainSrc": "images/img_rectangle107_3.png",
        "subSrc": "images/img_logomer06016_4.png",
        "alt": "Bluecheese logo",
        "title": "Bluecheese"
    },
    {
        "href": "http://www.andosrestaurant.com/",
        "mainSrc": "images/Andos-background.png",
        "subSrc": "images/Andos-logo.png",
        "alt": "Andos logo",
        "title": "Andos"
    },
    {
        "href": "http://www.didijollof.com/",
        "mainSrc": "images/didi-jollof-background.png",
        "subSrc": "images/didi-jollof-logo.png",
        "alt": "Didi Jollof logo",
        "title": "Didi Jollof"
    }
]

export { merchants };