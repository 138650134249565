import React from "react";
import PropTypes from "prop-types";

const shapes = { round: "rounded-lg" };
const variants = {
  fill: {
    indigo_800: "bg-indigo-800 text-white-A700",
    gray_100_01: "bg-gray-100_01 text-black-900",
    deep_orange_50: "bg-deep_orange-50 text-red-900",
    green_500_01: "bg-green-500_01 shadow-bs text-white-A700",
  },
  outline: {
    blue_gray_100:
      "border border-blue_gray-100 border-solid text-blue_gray-300",
  },
};
const sizes = { xs: "p-2", sm: "p-3", md: "p-[15px]", lg: "p-[19px]" };

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`md:p-1 sm:text-sm sm:min-h-[38px] p-3 ${className} ${(shape && shapes[shape]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf([
    "indigo_800",
    "gray_100_01",
    "deep_orange_50",
    "green_500_01",
    "blue_gray_100",
  ]),
};

export { Button };
